// export const SERVERURL = "localhost";
// export const FRONTENDURL = "http://localhost:3000/"
// export const AUTHAPIURL = `http://${SERVERURL}:5000/api/auth/`
// export const USERAPIURL = `http://${SERVERURL}:5000/api/webuser/`
// export const ADMINAPIURL = `http://${SERVERURL}:5000/api/webadmin/`
// export const EVENTIMG = `http://${SERVERURL}:5000/static/event_images/`
// export const PORTALQUES = `http://${SERVERURL}:5000/api/portalques/`
// export const PORTALADMINAPI = `http://${SERVERURL}:5000/api/portaladmin/`
// export const CODINGAPIURL = "https://judge0-ce.p.rapidapi.com/submissions/batch"

export const SERVERURL = "texephyr.live";    
export const FRONTENDURL = "https://events.texephyr.in/"
export const AUTHAPIURL = `https://${SERVERURL}/api/auth/`
export const USERAPIURL = `https://${SERVERURL}/api/webuser/`
export const ADMINAPIURL = `https://${SERVERURL}/api/webadmin/`
export const EVENTIMG = `https://${SERVERURL}/static/event_images/`
export const PORTALQUES = `https://${SERVERURL}/api/portal/questions/`
export const PORTALADMINAPI = `https://${SERVERURL}/api/portaladmin/`
export const CODINGAPIURL = "https://events.texephyr.live/submissions/batch"